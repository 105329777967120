import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Axios from 'axios'
import moment from 'moment'
import { FormattedNumber } from 'react-intl'
import { space } from 'styled-system'

import { useDebouncedEffect, useRouter, useSelectStore } from 'hooks'
import Navbar from 'components/Layout/Navbar'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  FlexBox,
  Div,
} from 'styledComponent'
import { spacing, borderRadius } from 'design-system/styles'
import {
  ButtonDropdown,
  Skeleton,
  ActiveFilterPills,
  Text,
  Breadcrumb,
  ActionCard,
  useToasts,
} from 'components/Common'
import { CustomerDetail } from './CustomerDetail'
import Filter from './Filter'
import { getCustomerById } from 'redux/actions/crmAction'

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import { Android, Apple, Phone, Male, Female, Other } from 'assets/icons'
import { BoxContent } from 'styledComponent/components/box'
import { PaginationWrapper } from '../bills/style'
import { $MarginlessLine } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { $SearchInput } from '../autoEngagement/AutoEngagement.styled'
import { isArray } from 'lodash'
import { isIframe } from 'utils/iframeUtils'

const $CRMContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: ${isIframe() ? 0 : spacing.l};
  min-height: ${isIframe() ? '100vh' : 'calc(100vh - 60px)'};

  > div {
    margin: ${spacing.m} 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    small {
      color: ${({ theme }) => theme.colors.error};
      text-decoration: underline;
    }
    span:nth-child(2) {
      display: flex;
    }
  }
  th {
    height: 90px;
    text-align: center;
  }
`

export const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${borderRadius.small};
  margin-right: ${spacing.ml};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) =>
      props.isFilter
        ? props.theme.colors.primary.default
        : props.theme.colors.success.bright};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
  ${space};
`

const setArray = (filter) => {
  let filData = Object.keys(filter)
    .filter((fil) => filter[fil]?.isActive)
    .map((filt) => filter[filt])
  return filData
}

const getGender = (gender) => {
  switch (gender) {
    case 'male':
      return <Male />
    case 'female':
      return <Female />
    case 'other':
      return <Other />
    default:
      return '-'
  }
}

export const CustomerProfiling = ({
  data,
  skip,
  tableLoading,
  onPageChange,
  handleFilter,
  initialFilter,
  filterData,
  search,
  setSearch,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const filterRef = React.useRef()
  const { history } = useRouter()
  const { toast } = useToasts()
  const {
    data: { currencyCode },
  } = useSelectStore('auth.user')

  const [loading, setLoading] = React.useState(true)
  const [showDetail, setShowDetail] = React.useState(false)
  const [isExport, setIsExport] = React.useState(false)
  const [customerData, setCustomerData] = React.useState({
    id: '',
    personal: {},
    professional: [],
    recent: [],
  })

  const onEdit = () => {
    history.push('/consumer-profiling/' + customerData.id)
  }

  const onPaginationChange = (page) => {
    setShowDetail(false)
    onPageChange(page)
  }

  const handleRemoveAll = () => {
    handleFilter(initialFilter)
    setShowDetail(false)
  }

  const handleClosePill = (id, type) => {
    handleFilter({
      ...filterData,
      [type]: initialFilter[type],
    })
    setShowDetail(false)
  }

  const addBtnClick = () => {
    history.push('/consumer-profiling/add')
  }

  const uploadBtnClick = () => {
    history.push('/consumer-profiling/add/upload')
  }

  const rowClick = (item) => {
    history.push('/consumer-profiling/' + item._id)
  }

  const getCustomer = async (id) => {
    try {
      setLoading(true)
      const res = await dispatch(getCustomerById(id))
      setLoading(false)
      setCustomerData({
        id,
        personal: res.personal[0],
        professional: res.professional[0],
        recent: res.activity,
      })
      setLoading(false)
    } catch (err) {
      if (Axios.isCancel(err)) return
      setLoading(false)
    }
  }

  const preCheck = (filter) => {
    let exit = false
    const array = Object.keys(filter)
    for (let ind = 0; ind < array.length; ind++) {
      const elem = array[ind]
      if (filter?.[elem]?.isActive) {
        if (
          filter?.[elem]?.value?.min === '' ||
          filter?.[elem]?.value?.max === ''
        ) {
          exit = true
          toast('Range cannot be empty', 'error')
          break
        }
        if (filter?.[elem]?.value?.min > filter?.[elem]?.value?.max) {
          exit = true
          toast(
            'Minimum value should not be greater than the Maximum value',
            'error'
          )
          break
        }
        if (
          isArray(filter?.[elem]?.value) &&
          filter?.[elem]?.value?.length === 0
        ) {
          exit = true
          toast('Please choose at least one option', 'error')
          break
        }
        if (['gender', 'relationship'].includes(elem)) {
          if (
            Object.keys(filter?.[elem]?.value)?.filter(
              (el) => filter?.[elem]?.value?.[el]
            )?.length === 0
          ) {
            exit = true
            toast('Please choose at least one option', 'error')
            break
          }
        }
      }
    }
    return exit
  }

  const onFilterHandle = (filter) => {
    if (preCheck(filter)) return
    setShowDetail(false)
    handleFilter(filter)
    filterRef.current.closeModal()
  }

  const showModal = (e, forExport = false) => {
    filterRef.current.openModal()
    setIsExport(forExport)
  }

  const onExport = (filter, discardFilters) => {
    if (preCheck(filter)) return
    handleFilter(filter, true, discardFilters)
    setIsExport(false)
    filterRef.current.closeModal()
  }

  return (
    <>
      {!isIframe() ? <Navbar heading="Consumer Profiling" /> : null}
      <Filter
        ref={filterRef}
        data={filterData}
        isExport={isExport}
        handleFilter={onFilterHandle}
        setIsExport={setIsExport}
        handleExport={onExport}
      />
      <$CRMContainer>
        {!isIframe() ? (
          <>
            <FlexBox my={spacing.l} justify="space-between">
              <Breadcrumb label="Consumer Profiling" />
            </FlexBox>
            <$MarginlessLine my={0} />
          </>
        ) : null}
        <FlexBox justify="flex-end !important" my="2px !important">
          <ButtonDropdown buttonText="Add/Upload" blockWidth="140px">
            <Text onClick={addBtnClick} variant="medium" type="grey">
              Add Manually
            </Text>
            <Text onClick={uploadBtnClick} variant="medium" type="grey">
              Bulk Upload
            </Text>
          </ButtonDropdown>
        </FlexBox>
        <$MarginlessLine my={0} />
        <Div>
          <span style={{ width: '100%' }}>
            {/* <SectionHeading
              heading="All customers"
              subHeading="List of all customers in your system"
              variant="medium"
              subVariant="small"
            /> */}
            <ActiveFilterPills
              ref={filterRef}
              pillData={setArray(filterData)}
              handleAdd={showModal}
              handleRemoveAll={handleRemoveAll}
              handleClosePill={handleClosePill}
            />
          </span>
          <span style={{ paddingRight: isIframe() ? spacing.l : '0' }}>
            <$SearchInput>
              <span />
              <input
                id="searchTxt"
                value={search}
                placeholder="Search by Phone or Email"
                onChange={(e) => setSearch(e.target.value)}
              />
            </$SearchInput>
            <$SquareButton isFilter onClick={showModal} size={40}>
              <FilterIcon />
            </$SquareButton>
            <$SquareButton mr={0} onClick={(e) => showModal(e, true)} size={40}>
              <ExcelIcon />
            </$SquareButton>
          </span>
        </Div>
        <Div mt={spacing.xl} />
        <BoxContent boxShadow="0px 2px 30px #00000016">
          <Table
            width={showDetail ? 'calc(100% - 350px)' : '100%'}
            margin="0px"
            borderColor={theme.colors.alto}
          >
            <TableHead>
              <TableRow fontSize="14px">
                <TableCell>Phone Number</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Total Visits</TableCell>
                <TableCell>Last Visit</TableCell>
                <TableCell>Avg Billing Amount</TableCell>
                <TableCell>OS</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {tableLoading
                ? [...new Array(10)].map((_, ind) => (
                    <TableRow key={ind} height="52px">
                      {[...new Array(9)].map((_, ind) => (
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : data.map((item) => (
                    <TableRow
                      key={item._id}
                      fontSize="12px"
                      height="52px"
                      highlightOnHover={theme.colors.solitude}
                    >
                      <TableCell>{item.phone || '-'}</TableCell>
                      <TableCell>{item.email || '-'}</TableCell>
                      <TableCell>{item.name || '-'}</TableCell>
                      <TableCell>{getGender(item?.gender)}</TableCell>
                      <TableCell>{item?.stats?.vCnt || '-'}</TableCell>
                      <TableCell>
                        {item?.stats?.lvDate
                          ? moment(item?.stats?.lvDate).format('DD/MM/YYYY')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.stats?.aSp ? (
                          <FormattedNumber
                            style="currency"
                            currency={currencyCode}
                            value={item?.stats?.aSp}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>
                        {item?.os ? <OS osType={item.os} /> : '-'}
                      </TableCell>
                      <TableCell textAlign="center">
                        <ActionCard
                          onIconClick={() => rowClick(item)}
                          components={['view']}
                          item=""
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              {!tableLoading && data.length === 0 && (
                <tr>
                  <th colSpan="7">No Data Found</th>
                </tr>
              )}
            </tbody>
          </Table>
          {showDetail && (
            <CustomerDetail
              loading={loading}
              onEdit={onEdit}
              recent={customerData.recent}
              professional={customerData.professional}
              personal={customerData.personal}
            />
          )}
        </BoxContent>
        <FlexBox justify="flex-end !important" pr={spacing.l}>
          <PaginationWrapper>
            <ul className="feedback-pagination notSelectableText">
              <li
                className={`${skip <= 0 ? 'disabled' : ''} previous`}
                onClick={() => skip > 0 && onPaginationChange(skip - 1)}
              >
                <a tabIndex="0" role="button" aria-disabled="false">
                  &lt; Previous
                </a>
              </li>
              <li
                className={`${data.length < 10 ? 'disabled' : ''} next`}
                onClick={() =>
                  data.length === 10 && onPaginationChange(skip + 1)
                }
              >
                <a tabIndex="1" role="button" aria-disabled="false">
                  Next &gt;
                </a>
              </li>
            </ul>
          </PaginationWrapper>
        </FlexBox>
      </$CRMContainer>
    </>
  )
}

export const OS = ({ osType, defaultText = '-' }) => {
  switch (osType) {
    case 'android':
      return (
        <>
          <Android />
        </>
      )
    case 'iphone':
      return (
        <>
          <Apple />
        </>
      )
    case 'other':
      return (
        <>
          <Phone />
        </>
      )
    default:
      return <>{defaultText}</>
  }
}
